import CryptoJS, { AES } from 'crypto-js'

export const encryptObject = (object: object, key: string): string => {
    return AES.encrypt(JSON.stringify(object), key).toString();
}

export const encryptString = (string: string, key: string): string => {
    return AES.encrypt(string, key).toString();
}

export const decryptObject = (object: string, key: string): object => {
    return JSON.parse(AES.decrypt(object, key).toString(CryptoJS.enc.Utf8));
}

export const decryptString = (string: string, key: string): string => {
    return AES.decrypt(string, key).toString(CryptoJS.enc.Utf8);
}