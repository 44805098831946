import React from 'react';
import PropTypes, { InferProps } from "prop-types"

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
      margin: 30,
      position: "static",
    },
    staticPosition: {
        position: "static"
    },
    media: {
      height: 300
    },
  }),
);

const hotelPropTypes = {
    link: PropTypes.string.isRequired,
    link_text: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired
}

type HotelProps = InferProps<typeof hotelPropTypes>;

const Hotel: React.FC<HotelProps> = ({link, link_text, text, image_url}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={image_url}
                title={text}
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
                {link_text}
            </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                {text}
                </Typography>
            </CardContent>
            <CardActions className={classes.staticPosition}>
                <Button className={classes.staticPosition} size="small" color="primary" href={link}>
                    Book
                </Button>
            </CardActions>
        </Card>
    )
}

export default Hotel;