import { useEffect, RefObject, MouseEvent } from 'react';

export const useOnClickOutside = (ref: RefObject<any>, handler: any) => {
  useEffect(() => {
    const listener: any = (event: MouseEvent<any>) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  },
  [ref, handler]);
};