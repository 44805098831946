import React from "react";
import styled from '@emotion/styled'

const TheSmallLink = styled('a')`
   color: black;
   text-decoration: none;
   font-size: 2rem;
   padding-top: 2rem;

   &:hover {
        text-decoration: underline;
    }
 `

type SmallLinkProps = {
    href: string
}

const SmallLink: React.FC<SmallLinkProps> = ({href, children}) => (
    <TheSmallLink rel="noopener noreferrer" target="_blank" href={href}>
        {children}
        <img alt="external link" src="/external-link-icon.png"/>
    </TheSmallLink>
)

export default SmallLink