import React, { useState } from "react";
import styled from '@emotion/styled'
import PropTypes, { InferProps } from 'prop-types'
import PacmanLoader from 'react-spinners/PacmanLoader';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Container from './container'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: 200,
      marginTop: '1rem',
    },
    submit: {
      marginTop: '1rem',
    },
  }),
);

const passwordModalPropTypes = {
    isLoading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    passwordError: PropTypes.bool.isRequired,
    handlePasswordChange: PropTypes.func.isRequired,
    handleUsernameChange: PropTypes.func.isRequired
}

type PasswordModalProps = InferProps<typeof passwordModalPropTypes>;

const Form = styled('form')`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
` 

const ErrorText = styled('p')`
  color: red;
`

const PasswordModal: React.FC<PasswordModalProps> = 
  ({ handleSubmit, isLoading, passwordError, handlePasswordChange, handleUsernameChange, children })  => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    return (
      <Container>
        {children}
        {isLoading ? (
          <PacmanLoader color="yellow" />
        ) : (
          <Form onSubmit={handleSubmit}>
            <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Username</InputLabel>
              <OutlinedInput
                  id="outlined-adornment-password"
                  type={'text'}
                  onChange={handleUsernameChange}
                  labelWidth={70}
                />
              </FormControl>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            {passwordError && <ErrorText>Incorrect login, please try again.</ErrorText>}
            <Button className={classes.submit} variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Form>
        )}
      </Container>
    )
  };

export default PasswordModal;