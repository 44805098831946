import React, { useState, useRef, RefObject, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Location from './location'
import Home from "./home";
import Travel from "./travel";
import Burger from "./components/burger";
import BurgerMenu from "./components/burgerMenu"
import { useOnClickOutside } from "./components/hooks"
import PasswordInput from "./components/passwordInput";
import { decryptObject, decryptString } from "./components/encryption";
import MainHeader from './components/mainHeader';
import NavBar from "./components/navBar";
import { setCookie, getCookie } from "./components/cookies";
import axios from 'axios';

const encLocationProps = `U2FsdGVkX19ioVVZpfZu+UjEmhiXy+EcbrYyHNWZtvNvx9lpTCbFJ8PXyP2ooCEsUsSFrGIjI35NTHMX4Pk8kMH5uSQOUlywjsEWxzurvoFKughJIyQjIq4JgckQtGNe3k+0Gb4ooKBBW1LbQFCrNYmRtFvc5pmj9+hX3P8uNHH3TfVTiDtK0p32iWqY6Y7H78hNP6YA3x6ZfTRmEpwH8/T60wGsO4q/f2ZcdvZcCCQ0AMCv7SETU2TJYho0pr041fzcBa5JzvJHaX20enAEjustVcSp0sXoGlzacP+Z0sveXJ2/Azek6JLTZ3jSjryqyb7qYkcSKbBwK3H9PErLvWGJQIqH7TYA+3MLAvmABKey5lL1SF3s6azN5GrKrUFPyBZx/YOR9rQQlw7+9IHkjd4WDIAYt/xdJokmJ9MOlaKs/FRsEipuGeIl/+Xvnm/uHkdHSnkGBvbWL0Vsf7h1TYpKicqjWZLlGsb4brtWQTZQS/wHvTGlueB729Yna4P+2sKGleLV4Y9RPiEH9cbLu5CX4O3eHfk5rlkf8PNLNnZ7yRJhF/tBrKRhzBUZQ7J54bwzlj5vxheOKucbP9z/0S+Gdwf/SEktclyhJpt0SOD3+B50mc2kROjGZJ9QMTP4ldbVgZNWuOZD7QkAPySEq/14R0z2O7yRec6PcNT3T/i7wD0CWjg7aSkfDw4F5pnA`;
const encTravelProps = `U2FsdGVkX19Lufl5Rpc7jTH2t8rYVXO7WgzrM9Tl1cxQ18yPRpZMY+kzGaGev3T4Tt4BR2rqYV3ZbMn7TEbL97Y7fV3KPcaExpWGVbFWkrxLnTcYRsWwMFpcJghOmh2nF8rV2j1Z7IjtC0WKKAb+c+Nq/tgeJI1N4HtC+qd2klvyr0AKdd+cPalIaam6H3YFJNMZYrZdJoE37FPqymemJGVQRjFpZdNxktgJP1wUFq177iZjw1FVJgKHvbbeqApBrdLIYwP/czsKqdmf3x+RGKlCe5TI4hKyfkVAwcC6l+OFTibzSEGGS3toq7ZXkyiPDL+hvOY2sXPwSEdoZE3dmhjFNP3q92u4xuWo/u01f7IqEClUOvD7Kj8ugbdnNQE8SrzDkp0nRaGNcUsRyJSXHRr4w/22pWD+SGedwndq9mPU99U3FLoHHjKGtjMLuGziXgM231MH4dbIbACupKqfTUhSIEEo1BSXUcdJqPNbhSSUViMnzSwd9gDHW//8vym7pIM8FBrzIfTkFd6WxeSjQcwKY6I/v8Q5n1hgHJn5Kb40/xfhIOp7cAbfDKMnifnEkF5ETb95GkIhylst0nlNK0pv+0mSZuL3Tj+tFvWfLR3MM8SXwSGOQh1WDmysmv/aVbc2ZLIZLfBWI66iliQEEnqbeBDPXzEnT37d52IYj2boGI25HY5hKTVo+seprv3t2JgVyIaGPl+RS7ABu4gXiPl8VAvvv53MT5R28+wHig1hL+WpJQ5ezCiZ7M2EOTObsbpVy04XFGw+no9ASkof6A==`;
const encUs = `U2FsdGVkX19ghP2hkF7TfF3d9hg8kh/gr99WD5/XBlo=`;
const encDate = `U2FsdGVkX19LZy12zGQyyiITIUeJtPW+VjFSkjmbucc=`;
// const encHomeText = `U2FsdGVkX19J61tuqqG4Phm/JTJaPTo6fDqi5j1nRKqmgVJAcJHaXIh4iH2a94hFCTmyIiFDD7yA4DQEW159VQ==`;
const encImgText = `U2FsdGVkX189BZhLZ+0U9WMfZjNXqGaqpXa6xzSbE1hghhktT8kqY0Tw4oY0WAxyqLq/v2B1pAnPfSo1L7iqNQyrN8SQ0WC+CQJYIYlnk3hqodIBaEgz19H13hzGAWzh`;
const userText = `U2FsdGVkX1/CehTJtj8vfa88dAINBr0mAwnYjZ4rORqBwI6cJSizvwXpTih07nQQ8yuL9ec7Zd35PYQc8hPMsh5OJ8Gwkhl6Jod5HiWxGFFeLLs3H9uJcdb1DybnUWn9biSefImL9CidnLhzuHrPqA==`;

const Main: React.FC = ({children}) => {
    
    const passwordCookie = getCookie('key');

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [locationProps, setLocationProps] = useState<any>();
    const [travelProps, setTravelProps] = useState<any>();
    const [us, setUs] = useState<string>('Wedding!');
    const [img, setImg] = useState<string>('');
    const [date, setDate] = useState<string>('Sometime!');
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [user, setUser] = useState<any>();

    const node: RefObject<any> = useRef(); 
    useOnClickOutside(node, () => setOpen(false));

    const decrypt = () => {
        try {
            // @ts-ignore
            const thePassword = passwordCookie || password.split('-')[0];
        
            setLocationProps(decryptObject(encLocationProps, thePassword));
            setTravelProps(decryptObject(encTravelProps, thePassword));
            const decryptedHeader = decryptString(encUs, thePassword);
            setUs(decryptedHeader);
            setDate(decryptString(encDate, thePassword));
            setImg(decryptString(encImgText, thePassword));
            document.title = decryptedHeader;

            setLoading(true);
            axios.get(decryptString(userText, thePassword), {
                auth: {
                    username: username,
                    password: password
                },
                withCredentials: true
            })
            .then(user => {
                setCookie('key', thePassword, 31);
                setPasswordError(false);
                setUser(user.data);
                setLoading(false);
            })
            .catch(error => {
                setPasswordError(true);
                setLoading(false);
            })
        } catch (error) {
            setPasswordError(true);
            setLoading(false);
        }
    };

    
    useEffect(() => {
        if(passwordCookie) {
            decrypt();
        }// eslint-disable-next-line
    }, [passwordCookie]);

    const handleTheSubmit = (event: any) => {
        event.preventDefault();
        decrypt();
    }

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    }

    const handleUsernameChange = (event: any) => {
        setUsername(event.target.value);
    }

    return (
        <React.Fragment>
        {user ? (
            <Router>
                <div ref={node}>
                        <Burger open={open} setOpen={setOpen} />
                        <BurgerMenu open={open} setOpen={setOpen}/>
                </div>
                 <NavBar us={us} date={date} />
                {children}
                <Switch>
                    <Route path="/location"><Location {...locationProps}/></Route>
                    <Route path="/photos"></Route>
                    <Route path="/travel"><Travel {...travelProps} password={password} username={username} /></Route>
                    <Route path="/rsvp"></Route>
                    <Route exact path="/"><Home yolo={img} /></Route>
                </Switch>
            </Router>
        ) : (
            <React.Fragment>
                <MainHeader header="Sup!?"/>
                <PasswordInput 
                        isLoading={loading}
                        handleSubmit={handleTheSubmit}
                        handlePasswordChange={handlePasswordChange}
                        handleUsernameChange={handleUsernameChange}
                        passwordError={passwordError}
                        />
            </React.Fragment>
        )}
        </React.Fragment>
    )
}

export default Main;