import React from "react";
import styled from '@emotion/styled'
import PropTypes, { InferProps } from 'prop-types'

const burgerPropTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
}

type BurgerProps = InferProps<typeof burgerPropTypes>;

const TheBurger = styled('button')<BurgerProps>`
    position: fixed;
    top: 0%;
    left: 0.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    img {
        max-width:100%;
    }
`
const Burger: React.FC<BurgerProps> = ({open, setOpen}) => (
    <TheBurger open={open} setOpen={setOpen} onClick={() => setOpen(!open)}>
        <img src="/hamburger.png" alt="hamburger menu" />
    </TheBurger>
)

Burger.propTypes = burgerPropTypes;

export default Burger;