import styled from '@emotion/styled'

const Container = styled('div')`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   max-width: 90%;
`

export default Container