import styled from '@emotion/styled'

const PageHeader = styled('header')`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
`
export default PageHeader