import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types'
import styled from '@emotion/styled'

const homePropTypes = {
    yolo: PropTypes.string.isRequired
}

type HomeProps = InferProps<typeof homePropTypes>;

const Img = styled('img')`
    margin-bottom: 8rem;
    image-orientation: from-image;
    margin-top: 6rem;
`

const Home: React.FC<HomeProps> = ({ yolo }) => {
    
    const [loaded, setLoaded] = useState<boolean>(false);

    return (
        <React.Fragment>
            {loaded ? (
            <Img src={yolo} alt="us" width="80%" height="80%" />
            ) : (
            <Img src="us.jpg" alt="us" width="80%" height="80%"
                onLoad={() => setLoaded(true)} />
            )}
        </React.Fragment>
    )
    }

export default Home;