import React from "react";
import styled from '@emotion/styled'
import { NavLink } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types"

const burgerMenuPropTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
}

type BurgerMenuProps = InferProps<typeof burgerMenuPropTypes>;

const TheBurgerMenu = styled('button')<BurgerMenuProps>`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    padding: 2rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    transform: ${({open}: BurgerMenuProps) => open ? 'translateX(0)' : 'translateX(-110%)'};
    -webkit-appearance: none;
    border-radius: 0;
    background-color: #FCFCFC;

    @media (max-width: 500px) {
        width: 100%;
    }

    div {
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        text-align: left;
        position: relative;
        //overflow-y: scroll;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        
    }

    a {
        font-size: 1rem;
        text-transform: uppercase;
        padding: 1rem 0;
        padding-right: 3rem;
        letter-spacing: 0.3rem;
        text-decoration: none;
        transition: color 0.3s linear;
        
        @media (max-width: 800px) {
            font-size: 1.5rem;
            text-align: center;
    }

    &:hover {
        text-decoration: underline;
    }
`

const MyLink = styled(NavLink)`
    font-size: 1.5rem;
    color: black;
    text-decoration: none;

    &.active {
        text-decoration: underline;
    }
`

const BurgerMenu: React.FC<BurgerMenuProps> = ({open, setOpen}) => (
    <TheBurgerMenu open={open} setOpen={setOpen}>
        <div>
            <MyLink to="/location" onClick={() => setOpen(false)}>location</MyLink>
            <MyLink to="/travel" onClick={() => setOpen(false)}>travel</MyLink>
            <MyLink exact to="/" onClick={() => setOpen(false)}>home</MyLink>
            <MyLink to="/photos" onClick={() => setOpen(false)}>photos</MyLink>
            <MyLink to="/rsvp" onClick={() => setOpen(false)}>rsvp</MyLink>
        </div>
    </TheBurgerMenu>
)

BurgerMenu.propTypes = burgerMenuPropTypes;

export default BurgerMenu;