import React from 'react';
import Main from './main';
import styled from '@emotion/styled'

const MainDiv = styled('div')`
  font-family: Aire-Bold-1;
  font-family: Aire-Bold-1;
  // font-size: 1.5rem;
  letter-spacing: 0.125em;
  word-spacing: 0em;
  line-height: 1.3em;
  text-transform: none;
  font-weight: normal;
  // , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  // Background for the whole app (besides burger menu)
  // background-color: #f9eae7;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const App: React.FC = () => (
  <MainDiv>
    <Main />
  </MainDiv>
)

export default App;
