import React from 'react';
import HorizontalLine from './horizontalLine';
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import PropTypes, { InferProps } from "prop-types"
import { toUpper } from 'lodash'

const navBarPropTypes = {
    us: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
}

type NavBarProps = InferProps<typeof navBarPropTypes>;

const NavList = styled('ul')`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1000px) {
        .navItem {
            visibility: hidden;
            height: 0vh;
            width: 0vh;
            margin: 0;
        }
    }
    
`

const NavItem = styled('li')`
    display: inline;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem
`

const MyLink = styled(NavLink)`
    font-size: 1rem;
    color: black;
    text-decoration: none;

    &.active {
        text-decoration: underline;
    }
`

const DateLink = styled(NavLink)`
    color: black;
    text-decoration: none;

    &.active {
        text-decoration: none;
    }
`

const Date = styled('div')`
    font-size: 1rem;
`

const Us = styled('div')`
    font-size: 1.5rem;
    display: inline;
`

const Home = styled('div')`
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const HomeItem = styled('li')`
    display: inline;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem
`

const Nav = styled('nav')`
    margin-top: 2rem;
    max-width: 100%;
`

const NavBar: React.FC<NavBarProps> = ({ us, date }) => (
    <Nav>
        <NavList>
            <NavItem className="navItem">
                <HorizontalLine/>
            </NavItem>
            <NavItem className="navItem">
                <MyLink to="/location">
                    location
                </MyLink>
            </NavItem>
            <NavItem className="navItem">
                <MyLink to="/travel">
                    travel
                </MyLink>
            </NavItem>
            <HomeItem>
                <DateLink exact to="/">
                <Home>
                    <Us>{toUpper(us)}</Us>
                    <Date>{toUpper(date)}</Date>
                </Home>
                </DateLink>
            </HomeItem>
            <NavItem className="navItem">
                <MyLink to="/photos">
                    photos
                </MyLink>
            </NavItem>
            <NavItem className="navItem">
                <MyLink to="/rsvp">
                    rsvp
                </MyLink>
            </NavItem>
            <NavItem className="navItem">
                <HorizontalLine/>
            </NavItem>
        </NavList>
    </Nav>
)

export default NavBar;