import React, { useEffect, useState } from "react";
import Container from './components/container'
import PageHeader from './components/pageHeader';
import Hotel from './components/hotel';
import PropTypes, { InferProps } from 'prop-types';
import axios from 'axios';
import { map } from 'lodash';
import styled from '@emotion/styled';
import PacmanLoader from 'react-spinners/PacmanLoader';

const LoadingContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`

const AirportBlurb = styled('p')`
    max-width: 70%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`

const Hotels = styled('div')`
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: wrap;
`

const travelPropTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    locationImageUrl: PropTypes.string.isRequired,
    locationImageAltText: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    airportsBlurb: PropTypes.string.isRequired
 }

type TravelProps = InferProps<typeof travelPropTypes>;

const Travel: React.FC<TravelProps> = ({ username, password, url, locationImageUrl, locationImageAltText, airportsBlurb }) => {
    const [hotelsCall, setHotelsCall] = useState({loading: true, error: false});
    const [hotels, setHotels] = useState();

    useEffect(() => {
        if(!hotels){
            axios.get(url, {
                auth: {
                    username: username,
                    password: password
                },
                withCredentials: true
            })
            .then((response: any) => {
                setHotels(response.data);
                setHotelsCall({loading: false, error: false})
            })
            .catch(_error => setHotelsCall({loading: false, error: true}));
        }
    }, [hotels, username, password, url]);

    return (
        <Container>
            <PageHeader>
                AIRPORTS
            </PageHeader>
            <AirportBlurb>{airportsBlurb}</AirportBlurb>
            <PageHeader>
               LODGING OPTIONS
                <img src={locationImageUrl} alt={locationImageAltText}></img>
            </PageHeader>
            {hotelsCall.loading && <LoadingContainer><PacmanLoader color="yellow" /></LoadingContainer>}
            {hotelsCall.error && <p>Something went wrong getting hotels.  Try refreshing the page.</p>}
            {hotels && 
                <Hotels>
                    {map(hotels, (hotel:any, key) => <Hotel key={key} text={hotel.text} link_text={hotel.link_text} link={hotel.link} image_url={hotel.image_url} />)}
                </Hotels>
            }
        </Container>
    )
}

export default Travel;