import React from "react";
import styled from '@emotion/styled'
import Container from './components/container'
import PageHeader from "./components/pageHeader";
import SmallLink from "./components/smallLink";
import PropTypes, { InferProps } from 'prop-types'
import { toUpper } from 'lodash'

const BigLink = styled('a')`
   color: black;
   text-decoration: none;
   font-weight: normal;

   &:hover {
      text-decoration: underline;
   }
`

const locationPropTypes = {
   locationName: PropTypes.string.isRequired,
   locationLink: PropTypes.string.isRequired,
   mapsLink: PropTypes.string.isRequired,
   mapPlaceLink: PropTypes.string.isRequired,
   addressLine1: PropTypes.string.isRequired,
   addressLine2: PropTypes.string.isRequired
}

type LocationProps = InferProps<typeof locationPropTypes>;

const Location: React.FC<LocationProps> = (props) => {
   return (
      <Container> 
         <PageHeader>
            <BigLink rel="noopener noreferrer" target="_blank" href={props.locationLink}>
                {toUpper(props.locationName)}
               <img alt="external link" src="/external-link-icon.png"/>
            </BigLink>
         </PageHeader>
            <iframe
               width="100%"
               height="450"
               title="Map"
               style={{border:0}}
               src={props.mapsLink}
            />
            <SmallLink href={props.mapPlaceLink}>
               {toUpper(props.addressLine1)}
               <br/> <br/>
               {toUpper(props.addressLine2)}
            </SmallLink>
      </Container>
   )
}

export default Location;