import React from 'react';
import Container from './container'
import styled from '@emotion/styled'
import PropTypes, { InferProps } from "prop-types"
import { toUpper } from 'lodash';

const mainHeaderPropTypes = {
    header: PropTypes.string.isRequired
}

type MainHeaderProps = InferProps<typeof mainHeaderPropTypes>;

const TheMainHeader = styled('header')`
    display: flex;
    flex-direction: column;
    align-items: ;center;
    // font-size: 600%;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    // font-family: Pacifico;
    // font-family: Aire-Bold-1;
    font-family: Aire-Bold-1;
    font-size: 1.5rem;
    letter-spacing: 0.125em;
    word-spacing: 0em;
    line-height: 1.3em;
    text-transform: none;
    font-weight: normal;
    word-wrap: break-word;
    max-width: 100%;
`

const MainHeader: React.FC<MainHeaderProps> = ({header, children}) => (
    <Container>
        <TheMainHeader>
            {toUpper(header)}
        </TheMainHeader>
        {children}
    </Container>
)

export default MainHeader;