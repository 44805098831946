import React from 'react';
import styled from '@emotion/styled'
import PropTypes, { InferProps } from "prop-types"

const horizontalLinePropTypes = {
    width: PropTypes.number
}

type HorizontalLineProps = InferProps<typeof horizontalLinePropTypes>;

const TheHorizontalLine = styled('div')<HorizontalLineProps>`
    border-style: solid;
    border-width: 0 0 1px 0;
    width: ${({width}: HorizontalLineProps) => width ?? '4'}rem;
`

const HorizontalLine: React.FC<HorizontalLineProps> = ({ width }) => {
    return <TheHorizontalLine width={width}/>
}

export default HorizontalLine;